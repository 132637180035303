































import Vue from "vue";
import { Layout, Theme } from "@/store/types";
import { mdiClose, mdiKeyboardBackspace } from "@mdi/js";

export default Vue.extend({
  name: "BoardTemplatePreviewForm",
  props: {
    item: {
      type: Object as () => Layout | Theme,
      required: true
    }
  },
  data: () => ({
    svgKeyboardBackspace: mdiKeyboardBackspace,
    svgClose: mdiClose
  })
});
